import React from 'react';

const Logo = ({ size = 'large' }) => {
    const sizes = {
        large: {
            fontSize: 'xxx-large',
            subTextFontSize: '50px',
            iconSize: '100px',
        },
        medium: {
            fontSize: 'xx-large',
            subTextFontSize: '40px',
            iconSize: '80px',
        },
        small: {
            fontSize: 'x-large',
            subTextFontSize: '30px',
            iconSize: '60px',
        },
    };

    const style = sizes[size] || sizes.large;

    const logoContainerStyle = {
        width: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '20px',
    };


    const logoSubtextStyle = {
        fontFamily: 'Impact, Arial Black, sans-serif',
        color: 'White',
        textShadow: `
            -2px -2px 0 #000,
            2px -2px 0 #000,
            -2px 2px 0 #000,
            2px 2px 0 #000
        `,
        fontWeight: 'bold',
        margin: '0 10px',
        fontSize: style.subTextFontSize,
    };

    const logoIconStyle = {
        borderRadius: '50%',
        boxShadow: '0 5px 10px rgba(0, 0, 0, 0.3)',
        width: style.iconSize,
        height: style.iconSize,
    };

    return (
        <div style={logoContainerStyle}>
            <span>dodoodle</span>
            {/* <span style={logoSubtextStyle}>Nouse</span> */}
        </div>
    );
};

export default Logo;
