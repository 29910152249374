import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { LanguageProvider } from './components/LanguageContext';
import { GameProvider } from './components/GameContext';
import LandingPage from './pages/LandingPage';
import GameApp from './components/GameApp';
import './App.css';

const App = () => {
  return (
    <LanguageProvider>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/play" element={
          <GameProvider>
            <GameApp />
          </GameProvider>
        } />
      </Routes>
    </LanguageProvider>
  );
};

export default App;
