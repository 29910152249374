// index.js
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import AppWrapper from './App';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';

// Google Analytics 초기화
ReactGA.initialize('G-GZELKLNLWM');

// 페이지뷰 트래킹 함수
const trackPageView = (page) => {
  console.log(`Tracking page view for: ${page}`); // 콘솔 출력
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

// 라우터 변경 감지 및 페이지뷰 트래킹
const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  return null;
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Router>
      <RouteTracker />
      <AppWrapper />
    </Router>
  </React.StrictMode>
);
