import React, { useState, useContext } from 'react';
import { GameContext } from './GameContext';
import { useLanguage } from './LanguageContext'; // Import the useLanguage hook

const CreateRoom = () => {
  const { createRoom, setHostName } = useContext(GameContext);
  const { language } = useLanguage(); // Use the useLanguage hook
  const [nickname, setNickname] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const handleCreateRoom = () => {
    if (nickname.trim() === '') {
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000);
      return;
    }
    setHostName(nickname);
    createRoom(nickname);
  };

  return (
    <div className="create-room">
      <h1>{language === 'en' ? 'Create Room' : '방 만들기'}</h1>
      <input 
        type="text" 
        placeholder={language === 'en' ? 'Enter your nickname' : '닉네임을 입력하세요'} 
        value={nickname} 
        onChange={(e) => setNickname(e.target.value)} 
      />
      <button onClick={handleCreateRoom}>{language === 'en' ? 'Create Room' : '방 만들기'} </button>
      {showPopup && (
        <div className="popup_error">
          <p>{language === 'en' ? 'Please enter a nickname' : '닉네임을 입력하세요'} </p>
        </div>
      )}
    </div>
  );
};

export default CreateRoom;
