import React, { createContext, useState, useEffect } from 'react';
import { ref, set, get, child, onValue, serverTimestamp } from 'firebase/database';
import { database } from '../firebase';

const GameContext = createContext();

const GameProvider = ({ children }) => {
  const [step, setStep] = useState(1);
  const [role, setRole] = useState(null);
  const [roomCode, setRoomCode] = useState('');
  const [players, setPlayers] = useState([]);
  const [hostName, setHostName] = useState('');
  const [guestName, setGuestName] = useState('');
  const [playerID, setPlayerID] = useState('');
  const [gameSettings, setGameSettings] = useState({
    keywords: false,
    timeLimit: 0,
    numberOfQuestions: 0,
    drawerSwitchMethod: 'sequential'
  });
  const [currentDrawer, setCurrentDrawer] = useState(null);
  const [scores, setScores] = useState({});
  const [currentKeyword, setCurrentKeyword] = useState('');
  const [isDrawing, setIsDrawing] = useState(false);
  const [showKeywordPopup, setShowKeywordPopup] = useState(false);
  const [isTypingKeyword, setIsTypingKeyword] = useState(false);
  const [correctGuess, setCorrectGuess] = useState('');
  const [showCorrectPopup, setShowCorrectPopup] = useState(false);
  const [showRankingPopup, setShowRankingPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const updateRoomTimestamp = (roomCode) => {
    const timestampRef = ref(database, `rooms/${roomCode}/lastActive`);
    set(timestampRef, serverTimestamp());
  };
  
  const createRoom = (hostName) => {
    const newRoomCode = Math.floor(10000000 + Math.random() * 90000000).toString();
    setRoomCode(newRoomCode);
    setHostName(hostName);
    setRole('host');
    setPlayerID(1);
    setCurrentDrawer(1);

    const initialData = {
      hostName: hostName,
      players: [{ name: hostName, id: 1 }],
      gameSettings: gameSettings,
      scores: { [hostName]: 0 },
      currentDrawer: 1,
      currentKeyword: '',
      isTypingKeyword: false,
      correctGuess: '',
      showCorrectPopup: false,
      showRankingPopup: false,
      lastActive: serverTimestamp(),
    };

    set(ref(database, `rooms/${newRoomCode}`), initialData);
    setStep(2);
  };

  const joinRoom = async (code, guestName) => {
    const roomRef = ref(database, `rooms/${code}`);
    const snapshot = await get(child(roomRef, `players`));
    const data = snapshot.val();
    if (data) {
      const existingPlayer = data.find(player => player.name === guestName);
      if (existingPlayer) {
        setErrorMessage('This nickname is already taken.');
        return;
      }
      const newId = data.length + 1;
      const updatedPlayers = [...data, { name: guestName, id: newId }];
      setRoomCode(code);
      setGuestName(guestName);
      setPlayerID(newId);
      setPlayers(updatedPlayers);
      set(ref(database, `rooms/${code}/players`), updatedPlayers);
      set(ref(database, `rooms/${code}/scores/${guestName}`), 0);
      setRole('guest');
      setStep(2); // Navigate to WaitingRoom after joining room
      setErrorMessage(''); // Reset error message on successful join
    } else {
      setErrorMessage('Room does not exist.'); // 에러 메시지 설정
    }
  };

  useEffect(() => {
    if (roomCode) {
      const playersRef = ref(database, `rooms/${roomCode}/players`);
      onValue(playersRef, (snapshot) => {
        const playersData = snapshot.val();
        if (playersData) {
          setPlayers(playersData);
        }
      });

      const settingsRef = ref(database, `rooms/${roomCode}/gameSettings`);
      onValue(settingsRef, (snapshot) => {
        const settingsData = snapshot.val();
        if (settingsData) {
          setGameSettings(settingsData);
        }
      });

      const scoresRef = ref(database, `rooms/${roomCode}/scores`);
      onValue(scoresRef, (snapshot) => {
        const scoresData = snapshot.val();
        if (scoresData) {
          setScores(scoresData);
        }
      });

      const drawerRef = ref(database, `rooms/${roomCode}/currentDrawer`);
      onValue(drawerRef, (snapshot) => {
        const drawerData = snapshot.val();
        if (drawerData) {
          setCurrentDrawer(drawerData);
        }
      });

      const keywordRef = ref(database, `rooms/${roomCode}/currentKeyword`);
      onValue(keywordRef, (snapshot) => {
        const keywordData = snapshot.val();
        if (keywordData) {
          setCurrentKeyword(keywordData);
        }
      });

      const typingRef = ref(database, `rooms/${roomCode}/isTypingKeyword`);
      onValue(typingRef, (snapshot) => {
        const typingData = snapshot.val();
        setIsTypingKeyword(typingData);
      });

      const correctGuessRef = ref(database, `rooms/${roomCode}/correctGuess`);
      onValue(correctGuessRef, (snapshot) => {
        const correctGuessData = snapshot.val();
        setCorrectGuess(correctGuessData);
      });

      const showCorrectPopupRef = ref(database, `rooms/${roomCode}/showCorrectPopup`);
      onValue(showCorrectPopupRef, (snapshot) => {
        const data = snapshot.val();
        setShowCorrectPopup(data);
      });

      const showRankingPopupRef = ref(database, `rooms/${roomCode}/showRankingPopup`);
      onValue(showRankingPopupRef, (snapshot) => {
        const data = snapshot.val();
        setShowRankingPopup(data);
      });
    }
  }, [roomCode]);

  const startGame = () => {
    if (gameSettings.keywords) {
      setIsTypingKeyword(true);
      set(ref(database, `rooms/${roomCode}/isTypingKeyword`), true);
    }
    set(ref(database, `rooms/${roomCode}/gameStatus`), 'started');
    setStep(3);
  };

  useEffect(() => {
    if (roomCode) {
      const statusRef = ref(database, `rooms/${roomCode}/gameStatus`);
      onValue(statusRef, (snapshot) => {
        const status = snapshot.val();
        if (status === 'started') {
          setStep(3);
        }
      });
    }
  }, [roomCode]);

  const endGame = () => {
    setStep(4); // Show ranking window
  };

  const nextDrawer = () => {
    if (gameSettings.drawerSwitchMethod === 'sequential') {
      const currentIndex = players.findIndex(player => player.id === currentDrawer);
      const nextIndex = (currentIndex + 1) % players.length;
      const newDrawer = players[nextIndex].id;
      setCurrentDrawer(newDrawer);
      set(ref(database, `rooms/${roomCode}/currentDrawer`), newDrawer);
      if (gameSettings.keywords) {
        setIsTypingKeyword(true);
        set(ref(database, `rooms/${roomCode}/isTypingKeyword`), true);
      }
    } else if (gameSettings.drawerSwitchMethod === 'correctAnswerFirst') {
      // Implement logic for switching drawer based on correct answer first
    }
  };

  const updateScore = (playerName, score) => {
    const updatedScores = { ...scores, [playerName]: (scores[playerName] || 0) + score };
    setScores(updatedScores);
    set(ref(database, `rooms/${roomCode}/scores`), updatedScores);
  };

  const handleKeywordInput = (keyword) => {
    setCurrentKeyword(keyword);
    setIsTypingKeyword(false);
    set(ref(database, `rooms/${roomCode}/currentKeyword`), keyword);
    set(ref(database, `rooms/${roomCode}/isTypingKeyword`), false);
    setIsDrawing(true);
  };

  const checkAnswer = (answer, playerID) => {
    const player = players.find(player => player.id === playerID);
    if (player && answer.toLowerCase() === currentKeyword.toLowerCase()) {
      alert('Correct Answer!');
      updateScore(player.name, 10);
      setCorrectGuess(player.name);
      set(ref(database, `rooms/${roomCode}/correctGuess`), player.name);
      setShowCorrectPopup(true);
      set(ref(database, `rooms/${roomCode}/showCorrectPopup`), true);
      setTimeout(() => {
        setShowCorrectPopup(false);
        set(ref(database, `rooms/${roomCode}/showCorrectPopup`), false);
        nextDrawer();
        setCurrentKeyword('');
        set(ref(database, `rooms/${roomCode}/currentKeyword`), '');
      }, 3000);
    }
  };

  const markCorrectAnswer = (playerID) => {
    const player = players.find(player => player.id === playerID);
    if (player) {
      updateScore(player.name, 10);
      setCorrectGuess(player.name);
      set(ref(database, `rooms/${roomCode}/correctGuess`), player.name);
      set(ref(database, `rooms/${roomCode}/showCorrectPopup`), true);
      updateRoomTimestamp(roomCode);
      setTimeout(() => {
        set(ref(database, `rooms/${roomCode}/showCorrectPopup`), false);
        nextDrawer();
      }, 3000);
    }
  };
  
    const handleEndGame = () => {
    setShowRankingPopup(true);
    set(ref(database, `rooms/${roomCode}/showRankingPopup`), true);
    console.log("Game ended. Setting showRankingPopup to true.");
  };

  // 드로어 변경 시 그림 데이터 초기화
  useEffect(() => {
    if (roomCode && currentDrawer) {
      // Firebase 그림 데이터 초기화
      const drawingRef = ref(database, `rooms/${roomCode}/draw`);
      set(drawingRef, null)
        .then(() => {
          console.log('Drawing cleared on drawer change');
        })
        .catch((error) => {
          console.error('Error clearing drawing:', error);
        });
    }
  }, [currentDrawer, roomCode]);

  return (
    <GameContext.Provider value={{
      step,
      setStep,
      role,
      players,
      roomCode,
      createRoom,
      joinRoom,
      hostName,
      setHostName,
      guestName,
      setGuestName,
      setRoomCode,
      startGame,
      endGame,
      gameSettings,
      setGameSettings,
      currentDrawer,
      nextDrawer,
      updateScore,
      scores,
      currentKeyword,
      setCurrentKeyword,
      isDrawing,
      setIsDrawing,
      showKeywordPopup,
      setShowKeywordPopup,
      handleKeywordInput,
      checkAnswer,
      isTypingKeyword,
      setIsTypingKeyword,
      correctGuess,
      showCorrectPopup,
      setShowCorrectPopup,
      showRankingPopup,
      setShowRankingPopup,
      markCorrectAnswer,
      playerID,
      setPlayerID,
      errorMessage,
      setErrorMessage,
      setPlayers, // Ensure setPlayers is provided
      setScores,  // Ensure setScores is provided
      setCorrectGuess, // Ensure setCorrectGuess is provided
    }}>
      {children}
    </GameContext.Provider>
  );
};

export { GameContext, GameProvider };
