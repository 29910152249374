import React, { useContext } from 'react';
import { GameContext } from './GameContext';
import './GameSettings.css';
import { FaInfinity } from 'react-icons/fa';
import { useLanguage } from './LanguageContext'; // Import the useLanguage hook


const GameSettings = ({ isHost }) => {
  const { gameSettings, setGameSettings } = useContext(GameContext);
  const { language } = useLanguage(); // Use the useLanguage hook

  if (!isHost) {
    return <div className="game-settings">
      <p>{language === 'en' ? 'Waiting for the host to start the game...' : '게임 시작을 기다리고 있습니다.'}</p>
    </div>; // 빈칸 렌더링
  }

  const handleKeywordsChange = (e) => {
    if (isHost) setGameSettings({ ...gameSettings, keywords: e.target.checked });
  };

  const handleTimeLimitChange = (change) => {
    if (isHost) {
      let newValue = gameSettings.timeLimit + change;
      if (newValue < 0) newValue = 0;
      if (newValue > 10) newValue = 10;
      setGameSettings({ ...gameSettings, timeLimit: newValue });
    }
  };

  const handleNumberOfQuestionsChange = (change) => {
    if (isHost) {
      let newValue = gameSettings.numberOfQuestions + change;
      if (newValue < 0) newValue = 0;
      if (newValue > 10) newValue = 10;
      setGameSettings({ ...gameSettings, numberOfQuestions: newValue });
    }
  };

  const handleDrawerSwitchMethodChange = (e) => {
    if (isHost) setGameSettings({ ...gameSettings, drawerSwitchMethod: e.target.value });
  };

  return (
    <div className="game-settings">
      <h3>{language === 'en' ? 'Game Settings' : '게임 방법 설정'}</h3>
      <div className="setting-item">
        <label>
          {language === 'en' ? 'Keywords Input Mode' : '낱말 입력모드'}
        </label>
        <input 
            type="checkbox" 
            checked={gameSettings.keywords} 
            onChange={handleKeywordsChange} 
            disabled={!isHost}
          />

      </div>
      <div className="setting-item">
        <label>{language === 'en' ? 'Time Limit Per Player' : '그림 그리는 시간 제한'}</label>
        <div className="counter">
          <button onClick={() => handleTimeLimitChange(-1)} disabled={!isHost || gameSettings.timeLimit <= 0}>-</button>
          <span>{gameSettings.timeLimit === 0 ? <FaInfinity /> : gameSettings.timeLimit}</span>
          <button onClick={() => handleTimeLimitChange(1)} disabled={!isHost || gameSettings.timeLimit >= 10}>+</button>
        </div>
      </div>
      <div className="setting-item">
        <label>{language === 'en' ? 'Limit Number of Questions Per Player' : '플레이어당 출제 수'}</label>
        <div className="counter">
          <button onClick={() => handleNumberOfQuestionsChange(-1)} disabled={!isHost || gameSettings.numberOfQuestions <= 0}>-</button>
          <span>{gameSettings.numberOfQuestions === 0 ? <FaInfinity /> : gameSettings.numberOfQuestions}</span>
          <button onClick={() => handleNumberOfQuestionsChange(1)} disabled={!isHost || gameSettings.numberOfQuestions >= 10}>+</button>
        </div>
      </div>
      <div className="setting-item">
        <label>
        {language === 'en' ? 'Drawer Switching Method' : '다음 그릴 사람 결정하기'}
        </label>
        <select 
            value={gameSettings.drawerSwitchMethod} 
            onChange={handleDrawerSwitchMethodChange} 
            disabled={!isHost || gameSettings.numberOfQuestions !== 0}
          >
            <option value="sequential">{language === 'en' ? 'Sequential' : '순서대로'}</option>
            <option value="correctAnswerFirst">{language === 'en' ? 'Correct Answer First' : '정답 맞힌 사람이 그리기'}</option>
          </select>
      </div>
    </div>
  );
};

export default GameSettings;
