import React, { useContext } from 'react';
import { GameContext } from './GameContext';
import { useLanguage } from './LanguageContext';
import { FaArrowLeft } from 'react-icons/fa';
import Logo from './Logo';
import CreateRoom from './CreateRoom';
import JoinRoom from './JoinRoom';
import WaitingRoom from './WaitingRoom';
import GameScreen from './GameScreen';

function GameApp() {
    const { step, setStep } = useContext(GameContext);
    const { language } = useLanguage();

    return (
        <div className="App">
            {step !== 1 && step !== 3 && (
                <button className="top-left" onClick={() => setStep(1)}>
                    <FaArrowLeft />
                </button>
            )}
            {step === 1 && (
                <div className="initial-screen">                    
                    <h1 className='logo'>dodoodle</h1>
                    <button onClick={() => setStep(4)}>
                        {language === 'en' ? 'Create Room' : '방 만들기'}
                    </button>
                    <button onClick={() => setStep(5)}>
                        {language === 'en' ? 'Join Room' : '방 참여'}
                    </button>
                </div>
            )}
            {step === 4 && <CreateRoom />}
            {step === 5 && <JoinRoom />}
            {step === 2 && <WaitingRoom />}
            {step === 3 && <GameScreen />}
        </div>
    );
}

export default GameApp; 