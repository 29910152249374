import React, { useState, useContext, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebase';
import { GameContext } from './GameContext';
import { useLanguage } from './LanguageContext'; // Import the useLanguage hook
import GameSettings from './GameSettings';
import { IoSettingsSharp } from "react-icons/io5";
import { FaPlay } from "react-icons/fa6";


const WaitingRoom = () => {
  const { roomCode, players, role, startGame, setGameSettings, setStep, gameStarted } = useContext(GameContext);
  const { language } = useLanguage(); // Use the useLanguage hook
  const [isPopupVisible, setIsPopupVisible] = useState(false); // State to manage the visibility of the popup

  useEffect(() => {
    // Room and players information logging
    console.log('WaitingRoom updated: roomCode', roomCode, 'players', players);
  }, [roomCode, players]);

  useEffect(() => {
    if (roomCode) {
      const settingsRef = ref(database, `rooms/${roomCode}/gameSettings`);
      onValue(settingsRef, (snapshot) => {
        const settingsData = snapshot.val();
        if (settingsData) {
          setGameSettings(settingsData);
        }
      });

      const gameStartedRef = ref(database, `rooms/${roomCode}/gameStarted`);
      onValue(gameStartedRef, (snapshot) => {
        const gameStartedData = snapshot.val();
        if (gameStartedData) {
          setStep(3); // 게임 모드로 이동
        }
      });
    }
  }, [roomCode, setGameSettings, setStep]);

  if (gameStarted) {
    return null; // 게임이 시작되면 대기실 화면을 숨김
  }
  const handleSettingClick = () => {
    setIsPopupVisible(true); // Show the popup when the Setting button is clicked
  };

  const handleSaveClick = () => {
    setIsPopupVisible(false); // Hide the popup when the Save button is clicked
  };
  return (
    <div className="waiting-room">
      {/* <h1>{language === 'en' ? 'Waiting Room' : '대기실'}</h1> */}
      <div className="room-code-container">
        <h2>{language === 'en' ? 'Room Code' : '방 번호'}</h2>
        <div className="room-code-cards">
          {roomCode.split('').map((digit, index) => (
            <div key={index} className="code-card">
              {digit}
            </div>
          ))}
        </div>
      </div>
      <div className='waiting-room-info'>
        <span></span>
      </div>

      <h2>{language === 'en' ? 'Players' : '참가자'}</h2>
      <ul className='players-list'>
        {players.map(player => (
          <li key={player.id}> <span className="player-id">{player.id}</span> <span className="player-name">{player.name}</span></li>
        ))}
      </ul> 

      
      {role === 'host' && (
        <div className='waiting-room-buttons'>
          <button className='start-game' onClick={startGame}><FaPlay />          </button>
        </div>
      )}
      {role === 'guest' && (
        <div>
          <p>{language === 'en' ? 'Waiting for the host to start the game...' : '호스트가 게임을 시작하기를 기다리는 중...'}</p>
        </div>
      )}

      {isPopupVisible && (
        <div className="popup-layer">
          <GameSettings isHost={role === 'host'} />
          <button className="save-button" onClick={handleSaveClick}>{language === 'en' ? 'Save' : '저장'}</button>
        </div>
      )}

    </div>
  );
};

export default WaitingRoom;
